import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@streamo/helper/types";
import { Image } from "gatsby-theme-ui";

const HeroContent6 = ({ title, coverImage }) => (
    <div className="single-slider relative h-[420px] md:h-[600px] bg-slate-100 bg-cover bg-bottom bg-no-repeat flex items-center">
        {coverImage?.src && (
            <div className="hero-image absolute left-0 top-0 h-full w-full">
                <Image
                    className="bg-cover object-cover bg-no-repeat h-full w-full"
                    src={coverImage?.src}
                    alt={coverImage?.alt || title || "Hero Image"}
                />
            </div>
        )}
    </div>
);

HeroContent6.propTypes = {
    title: PropTypes.string,
    coverImage: PropTypes.shape(ImageType),
};

export default HeroContent6;
