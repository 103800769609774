import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ButtonType, ItemType } from "@streamo/helper/types";
import { SectionTitleTwo, StreamItemCardTwo } from "gatsby-theme-component";

const VideoStreamingContainer = ({ data }) => (
    <section className="coming-soon-seciton bg-black section-space-pt-50">
        <div className="container-full px-4 md:px-24">
            {data?.section_title && (
                <SectionTitleTwo className="mb-3" {...data?.section_title} />
            )}

            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 gap-[30px]">
                {data?.items?.map((item) => (
                    <StreamItemCardTwo
                        key={item.id}
                        title={item?.title}
                        image={item?.image}
                        slug={item?.slug}
                        quality={item?.quality}
                        rating={item?.rating}
                        comingSoon={item?.comingSoon}
                    />
                ))}
            </div>
        </div>
    </section>
);

VideoStreamingContainer.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default VideoStreamingContainer;
