import React from "react";
import PropTypes from "prop-types";

const ContactUsInfo = ({ data }) => (
    <div className="contact-info-wrap">
        <div className="container">
            <div className="grid">
                <div
                    className="contact-info mt-20 md:mt-0 md:absolute md:top-1/2 md:-translate-y-1/2 p-5 md:p-[48px_50px] xl:p-[48px_100px] w-full md:w-[490px] xl:w-[590px]
                        md:right-[50px] xl:right-[250px] rounded-md bg-black"
                >
                    <div className="single-contact-info flex items-center mb-[30px] pb-[30px] border-b border-solid border-white">
                        <div className="contact-info-icon shrink-0 bg-[#333] w-12 h-12 text-white text-center leading-[48px] rounded-full text-lg mr-4">
                            <i className="zmdi zmdi-home" />
                        </div>
                        <div className="contact-info-text">
                            <p className="text-white">
                                {data?.contact_info?.address}
                            </p>
                        </div>
                    </div>
                    <div className="single-contact-info flex items-center mb-[30px] pb-[30px] border-b border-solid border-white">
                        <div className="contact-info-icon shrink-0 bg-[#333] w-12 h-12 text-white text-center leading-[48px] rounded-full text-lg mr-4">
                            <i className="zmdi zmdi-phone" />
                        </div>
                        <div className="contact-info-text">
                            <p className="text-white">
                                {data?.contact_info?.phone}
                            </p>
                        </div>
                    </div>
                    <div className="single-contact-info flex items-center mb-[30px] pb-[30px] border-b border-solid border-white">
                        <div className="contact-info-icon shrink-0 bg-[#333] w-12 h-12 text-white text-center leading-[48px] rounded-full text-lg mr-4">
                            <i className="zmdi zmdi-email" />
                        </div>
                        <div className="contact-info-text">
                            <p className="text-white">
                                {data?.contact_info?.email}
                            </p>
                        </div>
                    </div>
                    <div className="single-contact-info flex items-center">
                        <div className="contact-info-icon shrink-0 bg-[#333] text-white w-12 h-12 text-center leading-[48px] rounded-full text-lg mr-4">
                            <i className="zmdi zmdi-globe-alt" />
                        </div>
                        <div className="contact-info-text">
                            <p className="text-white">
                                {data?.contact_info?.website}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

ContactUsInfo.propTypes = {
    data: PropTypes.shape({
        contact_info: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string,
            website: PropTypes.string,
            address: PropTypes.string,
        }),
    }),
};

export default ContactUsInfo;
