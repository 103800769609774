import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { ImageType } from "@streamo/helper/types";
import { Image } from "gatsby-theme-ui";

const StreamCard = ({ title, slug, image, quality, rating }) => {
    const allRating = [];
    const notRating = [];
    for (let i = 0; i < rating; i++) {
        allRating.push(<i key={i} className="zmdi zmdi-star mr-1" />);
    }
    if (rating < 5) {
        for (let j = 1; j <= 5 - rating; j++) {
            notRating.push(<i key={j} className="zmdi zmdi-star mr-1" />);
        }
    }
    return (
        <div className="single-product-wrap text-center relative overflow-hidden rounded-lg group">
            <div
                className="product-image relative overflow-hidden
            before:absolute before:content-[''] before:left-0 before:right-0 before:top-0 before:bottom-0 before:z-[3]
            before:rounded-lg before:bg-gradient-to-t before:from-[#072e4a_25%] before:to-[transparent_60%] before:opacity-0 group-hover:before:opacity-100
            before:transition-[all_0.5s_ease_0s]"
            >
                {image?.src && (
                    <Link to={slug} className="block">
                        <Image
                            className="rounded w-full transition-[all_0.5s_ease_0s] group-hover:transform scale-100 group-hover:scale-110"
                            src={image.src}
                            alt={image.alt || title}
                        />
                    </Link>
                )}
            </div>

            <div className="product-contents mt-1 opacity-0 group-hover:opacity-100 invisible group-hover:visible transition-[all_0.3s_ease_0s] absolute left-0 right-0 text-center z-10 bottom-3 p-3">
                {title && (
                    <h5 className="font-medium mb-1 transition-[all_0.5s_ease_0s] translate-y-8 group-hover:translate-y-0">
                        <Link to={slug} className="text-white">
                            {title}
                        </Link>
                    </h5>
                )}
                {quality && (
                    <span className="text-white mb-2 block transition-[all_0.7s_ease_0s] translate-y-8 group-hover:translate-y-0">
                        Quality : {quality}
                    </span>
                )}
                <div className="button transition-[all_0.8s_ease_0s] translate-y-8 group-hover:translate-y-0">
                    <Link
                        to={slug}
                        className="text-white bg-red-500 inline-block text-center px-3 py-1 text-sm rounded-sm"
                    >
                        Watch Now
                    </Link>
                </div>
            </div>
        </div>
    );
};

StreamCard.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    quality: PropTypes.string,
    image: PropTypes.shape(ImageType),
    rating: PropTypes.number,
};

export default StreamCard;
