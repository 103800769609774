import PropTypes from "prop-types";
import { Portal } from "react-portal";
import ReactModalVideo from "react-modal-video";
import "./modal-video.min.css";

const ModalVideo = ({ channel, isOpen, videoId, onClose }) => (
    <Portal>
        <div>
            <ReactModalVideo
                channel={channel}
                isOpen={isOpen}
                videoId={videoId}
                onClose={onClose}
            />
        </div>
    </Portal>
);

ModalVideo.propTypes = {
    channel: PropTypes.string,
    isOpen: PropTypes.bool,
    videoId: PropTypes.string,
    onClose: PropTypes.func,
};

ModalVideo.defaultProps = {
    channel: "youtube",
};

export default ModalVideo;
