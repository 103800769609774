/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import cn from "classnames";

const titleStyle = {
    left: `text-left`,
    right: `text-right`,
    center: `text-center`,
};
const colorStyle = {
    primary: "text-primary",
    black: `text-black`,
    white: `text-white`,
};
const SectionTitle = ({
    image,
    title,
    description,
    position,
    className,
    color,
}) => {
    const sectionTitleStyle = {
        position: position || "center",
        color: color || "primary",
    };
    const titleClasses = cn(
        className,
        "section-title border-b border-solid border-[#444] pb-[10px] mb-8",
        titleStyle[sectionTitleStyle.position]
    );
    const colorClasses = cn(
        " !text-[24px] font-bold",
        colorStyle[sectionTitleStyle.color]
    );
    return (
        <div className={titleClasses}>
            {title && <h2 className={colorClasses}>{title}</h2>}
            {image && <Image src={image.src} alt={title} />}
            {description && <p className={colorClasses}>{description}</p>}
        </div>
    );
};
SectionTitle.propTypes = {
    title: PropTypes.string,
    image: PropTypes.shape({
        src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})])
            .isRequired,
        alt: PropTypes.string,
    }),
    description: PropTypes.string,
    position: PropTypes.oneOf(["left", "right", "center"]),
    color: PropTypes.oneOf(["primary", "black", "white"]),
};
SectionTitle.defaultProps = {
    position: "center",
    color: "primary",
};
export default SectionTitle;
