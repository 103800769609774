import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { ImageType } from "@streamo/helper/types";
import { Image } from "gatsby-theme-ui";

const BannerContent = ({ title, slug, coverImage }) => (
    <div className="single-slider relative overflow-hidden h-[400px] md:h-[600px] group">
        {coverImage?.src && (
            <Link to={slug} className="hero-image">
                <Image
                    className="bg-cover absolute left-0 top-0 bg-no-repeat h-full w-full object-cover
                    transition-[all_0.6s_ease_0s] group-hover:transform scale-100 group-hover:scale-105
                    "
                    src={coverImage?.src}
                    alt={coverImage?.alt || title || "Hero Image"}
                />
            </Link>
        )}
    </div>
);

BannerContent.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    coverImage: PropTypes.shape(ImageType),
};

export default BannerContent;
