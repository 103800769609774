import React from "react";
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import { ImageType } from "@streamo/helper/types";

const Feature = ({ title, images, description }) => (
    <div className="single-feature text-left flex">
        <div
            className="singel-feature-icon shrink-0 text-center bg-gradient-to-l from-[#0259ae] to-[#12b4f2] mb-5 h-12 w-12
         leading-[48px] rounded-full shadow-md"
        >
            <Image
                className=" align-middle"
                src={images?.src}
                alt={images?.alt || title}
            />
        </div>
        <div className="content ml-6">
            <h5 className="mb-3 font-normal text-white">{title}</h5>
            <p className="text-sm leading-6 text-white">{description}</p>
        </div>
    </div>
);

Feature.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.shape(ImageType),
};

export default Feature;
