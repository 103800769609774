import React from "react";
import PropTypes from "prop-types";
import { ItemType } from "@streamo/helper/types";
import { FunFact } from "gatsby-theme-component";

const FunFactArea = ({ data }) => (
    <section className="funfact-section bg-black section-space-ptb-90">
        <div className="container">
            <div className="counter-area text-white sm:flex md:flex sm:justify-between md:justify-between">
                {data?.items?.map((item) => (
                    <FunFact
                        key={item.id}
                        title={item?.title}
                        number={item?.number}
                        images={item?.images[0]}
                    />
                ))}
            </div>
        </div>
    </section>
);

FunFactArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default FunFactArea;
