/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const MainMenu = ({ menu }) => (
    <ul className="flex items-center">
        {menu?.map((item) => {
            const { submenu } = item;
            const hasSubmenu = submenu?.length > 0;
            return (
                <li
                    key={item.id}
                    className={`${
                        hasSubmenu ? `${hasSubmenu}` : ""
                    } group relative pt-4 pb-4 cursor-pointer leading-[60px] text-white z-10
                    before:transition-all before:opacity-0`}
                >
                    <Link
                        to={item.link}
                        className="active capitalize text-sm mx-4"
                    >
                        {item.text}
                    </Link>
                    {hasSubmenu && (
                        <ul className="submenu-nav absolute left-0 z-50 bg-white mt-14 opacity-0 invisible group-hover:opacity-100 group-hover:visible group-hover:mt-4 transition-all duration-500 min-w-[200px] p-4">
                            {submenu.map((subitem) => (
                                <li key={subitem.id}>
                                    <Link
                                        to={subitem.link}
                                        className="menu-sub-item text-sm font-medium text-black block py-1 hover:text-primary"
                                    >
                                        {subitem.text}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            );
        })}
    </ul>
);
MainMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default MainMenu;
