import { useState, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { ImageType, ButtonType } from "@streamo/helper/types";
import { BurgerButton } from "gatsby-theme-ui";
import { Link } from "gatsby";

import {
    Logo,
    MainMenu,
    MenuOffcanvas,
    NotificationCard,
    ProfileCard,
    SearchBox01,
} from "gatsby-theme-component";

const Header = ({ data }) => {
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);
    const offCanvasHandler = useCallback(() => {
        setOffCanvasOpen((prev) => !prev);
    }, [setOffCanvasOpen]);

    return (
        <Fragment>
            <header className="header py-5 lg:py-0 px-4 lg:px-10 xl:px-24 bg-black mx-auto z-[99999] top-0 w-full">
                <div className="flex justify-between items-center">
                    <div className="left-sidebar flex items-center">
                        {data?.logo && <Logo mainLogo={data.logo?.[0]} />}
                        <div className="menu ml-10 hidden lg:block">
                            <MainMenu menu={data?.menu} />
                        </div>
                    </div>
                    <div className="right-sidebar flex items-center space-x-4">
                        <SearchBox01 />
                        <NotificationCard />
                        <ProfileCard />

                        <div className="hidden sm:block">
                            <Link
                                to="/login-register"
                                className=" bg-red-500 px-5 py-2 font-semibold text-sm text-white rounded-md"
                            >
                                Subscribe Now
                            </Link>
                        </div>
                        <div className="lg:hidden">
                            <BurgerButton onClick={offCanvasHandler} />
                        </div>
                    </div>
                </div>
            </header>
            <MenuOffcanvas
                isOpen={offCanvasOpen}
                onClick={offCanvasHandler}
                menu={data?.menu}
            />
        </Fragment>
    );
};
Header.propTypes = {
    data: PropTypes.shape({
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        button: PropTypes.shape(ButtonType),
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};
export default Header;
