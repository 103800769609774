import React from "react";
import { Anchor } from "gatsby-theme-ui";
import PropTypes from "prop-types";
import cn from "classnames";

const BreadCrumb = ({ pageName, prevList, className, showTitle, type }) => (
    <div
        className={cn(
            "page-title-content container-full py-5 px-4 flex justify-center align-bottom",
            className
        )}
    >
        {showTitle && (
            <h1 className="title text-2xl text-[25px] text-white capitalize mr-4">
                {type && <span>{type} :</span>} {pageName}
            </h1>
        )}
        <div className="bread-crumbs mt-2 mr-4 inline-block text-white">
            {prevList.map((item) => (
                <Anchor key={item.title} path={item.path}>
                    {item.title}
                </Anchor>
            ))}
            <span className="px-2">|</span>
            <span className="text-white"> {pageName}</span>
        </div>
    </div>
);
BreadCrumb.propTypes = {
    pageName: PropTypes.string.isRequired,
    className: PropTypes.string,
    showTitle: PropTypes.bool,
    prevList: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            path: PropTypes.string,
        })
    ),
    type: PropTypes.string,
};

BreadCrumb.defaultProps = {
    prevList: [{ title: "Home", path: "/" }],
};
export default BreadCrumb;
