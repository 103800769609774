/* eslint-disable no-nested-ternary */
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import { Image } from "gatsby-theme-ui";
import { ImageType } from "@streamo/helper/types";

const StramingDetails = ({ data }) => {
    const pageLink =
        typeof window !== "undefined" ? window.location.href : null;
    return (
        <Fragment>
            <div className="header bg-primary-90 text-white">
                <div className="container-full py-5 px-4 flex justify-center align-bottom">
                    {data?.title && <h1 className="text-2xl">{data?.title}</h1>}
                    <div className="other mt-1">
                        {data?.watchTime && (
                            <span className="mb-0">
                                <span className="px-2">|</span>{" "}
                                {data?.watchTime}{" "}
                                <span className="px-2">|</span>
                            </span>
                        )}
                        {data?.quality && (
                            <span className="mb-0">{data?.quality}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-black text-white section-space-pt-60">
                <div className="container">
                    <div className="video-wrapper relative pt-[56.25%]">
                        {data?.streaming_video_link ? (
                            <ReactPlayer
                                className="react-player absolute top-0 left-0 w-full h-full"
                                controls
                                playing
                                url={data?.streaming_video_link}
                                light={
                                    data?.coverImage?.src?.publicURL ||
                                    data?.image?.src?.publicURL
                                }
                                width="100%"
                                height="100%"
                            />
                        ) : data?.coverImage?.src?.publicURL ? (
                            <div className="text-center">
                                <Image
                                    className="mb-3"
                                    src={data?.coverImage?.src}
                                    alt={data?.title}
                                />{" "}
                                <br />
                                Video is Not Available Yet
                            </div>
                        ) : (
                            <div className="text-center">
                                <Image
                                    className="mb-3"
                                    src={data?.image?.src}
                                    alt={data?.title}
                                />{" "}
                                <br />
                                Video is Not Available Yet
                            </div>
                        )}
                    </div>

                    <div className="streaming-content mt-8">
                        <div className="streaming-dec mt-5">
                            <div className="shot-dec">
                                {data?.director && (
                                    <p>
                                        <strong>Director:</strong>{" "}
                                        {data?.director}
                                    </p>
                                )}
                                {data?.date && (
                                    <p>
                                        <strong>Date: </strong>

                                        {data?.date}
                                    </p>
                                )}
                            </div>
                            {data?.overview &&
                                data?.overview.map((item) => (
                                    <p className="mt-4" key={item.id}>
                                        <span>{item.content}</span>
                                    </p>
                                ))}
                        </div>
                        <div className="share-wrap mt-8 border-t border-b py-3 border-solid border-[#444] flex items-center">
                            <strong className="mr-3">Share:</strong>
                            <div className="share-post mt-8 md:mt-0">
                                <a
                                    className="mr-3 rounded-full inline-block text-lg w-9 h-9 leading-9 text-white bg-[#3b5998] text-center "
                                    href={`http://www.facebook.com/sharer.php?u=${pageLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to facebook"
                                >
                                    <i className="zmdi zmdi-facebook" />
                                </a>
                                <a
                                    className="mr-3 rounded-full inline-block bg-[#00B6F1] text-lg w-9 h-9 leading-9 text-white text-center"
                                    href={`http://twitter.com/share?url=${pageLink}&text=${data?.title}&hashtags=hashtags`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to twitter"
                                    sx={{ color: "twitter" }}
                                >
                                    <i className="zmdi zmdi-twitter" />
                                </a>
                                <a
                                    className="mr-3 rounded-full inline-block bg-[#0A66C2] text-lg w-9 h-9 leading-9 text-white text-center "
                                    href={`http://www.linkedin.com/shareArticle?mini=true&url=${pageLink}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-label="Share to linkedin"
                                    sx={{ color: "linkedin" }}
                                >
                                    <i className="zmdi zmdi-linkedin" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

StramingDetails.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        overview: PropTypes.arrayOf(
            PropTypes.shape({ content: PropTypes.string, id: PropTypes.string })
        ),
        date: PropTypes.string,
        quality: PropTypes.string,
        watchTime: PropTypes.string,
        director: PropTypes.string,
        streaming_video_link: PropTypes.string,
        image: PropTypes.shape(ImageType),
        coverImage: PropTypes.shape(ImageType),
    }),
};

export default StramingDetails;
