/* eslint-disable max-len */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const TabContentItem = () => (
    <div className="tab-content-box">
        <div className="our-profile-images grid gap-6 lg:gap-10 grid-cols-1 lg:grid-cols-12 mb--60 ">
            <div className="profile-media grid-cols-1 lg:col-span-3 xl:col-span-2">
                <div className="edit-profile relative inline-block mb-10">
                    <StaticImage
                        src="../../profile.jpg"
                        alt="A author"
                        placeholder="blurred"
                        className="rounded-md"
                    />
                    <div className="btn-file absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 m-auto">
                        <span className="btn-file-icon text-white p-3 text-3xl">
                            <i className="zmdi zmdi-camera-bw" />
                        </span>
                        <input type="file" />
                    </div>
                </div>
            </div>
            <div className="grid-cols-1 lg:col-span-9 xl:col-span-10">
                <div className="form-info text-white">
                    <div className="top-area mb-8">
                        <h3 className="title mb-5">General Information</h3>
                        <p>
                            By letting us know your name, we can make our
                            support experience much more personal.
                        </p>
                    </div>

                    <div className="grid gap-8 grid-cols-1 sm:grid-cols-2">
                        <div className="col-span-1">
                            <div className="input-group">
                                <label
                                    className="mb-1 text-[15px] block"
                                    htmlFor="fist-name"
                                >
                                    First Name
                                </label>
                                <input
                                    className="bg-primary-90 text-[15px] w-full py-3 px-4 placeholder:text-white text-white"
                                    type="text"
                                    id="con_name"
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="input-group">
                                <label
                                    className="mb-1 text-[15px] block"
                                    htmlFor="fist-name"
                                >
                                    Last Name
                                </label>
                                <input
                                    className="bg-primary-90 w-full  text-[15px] py-3 px-4 placeholder:text-white text-white"
                                    type="text"
                                    id="con_last_name"
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="input-group">
                                <label
                                    className="mb-1 text-[15px] block"
                                    htmlFor="Display-Name"
                                >
                                    Display Name
                                </label>
                                <input
                                    className="bg-primary-90 w-full  text-[15px] py-3 px-4 placeholder:text-white text-white"
                                    type="text"
                                    id="con_display_name"
                                    placeholder="Display Name"
                                    defaultValue="Brent Robinson"
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="input-group">
                                <label
                                    className="mb-1 text-[15px] block"
                                    htmlFor="con_email"
                                >
                                    Email Address
                                </label>
                                <input
                                    className="bg-primary-90 w-full py-3 text-[15px] px-4 placeholder:text-white text-white"
                                    type="email"
                                    id="con_email"
                                    placeholder="Enter Your Email"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="password-change-wrap mt-10 pt-10 border-t border-[#444]">
                        <h3 className="title mb-5">Password change</h3>
                        <div className="grid gap-8 grid-cols-1 sm:grid-cols-3">
                            <div className="col-span-1">
                                <div className="input-group">
                                    <label
                                        className="mb-1  text-[15px] block"
                                        htmlFor="current_password"
                                    >
                                        Current Password:
                                    </label>
                                    <input
                                        className="bg-primary-90 text-[15px] w-full py-3 px-4 placeholder:text-white text-white"
                                        type="password"
                                        id="current_password"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="input-group">
                                    <label
                                        className="mb-1 text-[15px] block"
                                        htmlFor="new_password"
                                    >
                                        New Password:
                                    </label>
                                    <input
                                        className="bg-primary-90 w-full py-3 px-4 text-[15px] placeholder:text-white text-white"
                                        type="password"
                                        id="new_password"
                                    />
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="input-group">
                                    <label
                                        className="mb-1 text-[15px] block"
                                        htmlFor="confirm-password"
                                    >
                                        Confirm Password:
                                    </label>
                                    <input
                                        className="bg-primary-90 w-full py-3 px-4 text-[15px] placeholder:text-white text-white"
                                        type="password"
                                        id="confirm_password"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="button-wrap mt-10 pt-10 border-t border-[#444]">
                        <button
                            type="submit"
                            className=" bg-red-500 text-[14px] text-white hover:bg-red-600 px-5 py-3"
                        >
                            Save Changes
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
);
TabContentItem.propTypes = {};
export default TabContentItem;
