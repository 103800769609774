/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { normalizedData } from "@streamo/helper/methods";
import {
    FooterTextWidgetTwo,
    FooterMenuWidgetTwo,
    FooterMenuWidgetThree,
    FooterPartnerWidget,
} from "gatsby-theme-component";

const FooterArea = ({ data }) => {
    const widgets = normalizedData(data?.widgets || [], "widget");
    return (
        <footer className="footer-area section-space-pt-50 bg-black">
            <div className="container-full px-4 lg:px-10 xl:px-24 border-t border-[#444] border-1">
                <div className="footer-center-area pb-10 grid sm:gap-x-12 grid-cols-1 md:grid-cols-12">
                    <div className="single-col md:col-span-5 lg:col-span-3 mt-8 sm:mt-14 pr-1 lg:pr-12">
                        <FooterTextWidgetTwo
                            data={{
                                logo: data?.logo,
                                ...widgets?.["text-widget"],
                                socials: data?.socials,
                            }}
                        />
                    </div>

                    <div className="single-col md:col-span-7 lg:col-span-9 xl:ml-16 mt-8 sm:mt-14">
                        <FooterMenuWidgetTwo
                            data={widgets?.["streamo-footer-link-widget"]}
                        />
                        <FooterPartnerWidget
                            data={widgets?.["footer-brand-widget"]}
                        />
                        <FooterMenuWidgetThree
                            data={widgets?.["streamo-footer-link-two-widget"]}
                        />
                    </div>
                </div>
            </div>
            <div className="footer-bottom-area py-4 border-t border-solid border-[#444]">
                <div className="container-full px-4 lg:px-10 xl:px-24">
                    <div className="footer-bottom-text flex flex-col sm:flex-row justify-between items-center">
                        <div className="copy-right-area py-2">
                            <p className="text-white text-sm">
                                {data?.copyright_text}
                            </p>
                        </div>
                        <div className="menu flex items-center pb-2">
                            <p className="text-white text-sm mr-4">
                                Already have an Account?{" "}
                                <a
                                    href="/streaming-two/login-register"
                                    className="font-semibold border-b leading-3 hover:text-red-600"
                                >
                                    {" "}
                                    LOGIN
                                </a>
                            </p>
                            <a
                                href="/streaming-two/login-register"
                                className="px-6 py-2 bg-red-500 text-white text-sm rounded-sm hover:bg-red-600"
                            >
                                Become a Member
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
FooterArea.propTypes = {
    data: PropTypes.shape({
        widgets: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                menu: PropTypes.arrayOf(PropTypes.shape({})),
            })
        ),
        copyright_text: PropTypes.string,
        contact_info: PropTypes.shape({
            phone: PropTypes.string,
            email: PropTypes.string,
        }),
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        logo: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};
export default FooterArea;
