import React, { useState } from "react";
import PropTypes from "prop-types";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { ImageType } from "@streamo/helper/types";
import { Image } from "gatsby-theme-ui";

const FunFactArea = ({ title, number, images }) => {
    const [focus, setFocus] = useState(false);
    const visibleChangeHandler = (isVisible) => {
        if (isVisible) {
            if (!focus) {
                setFocus(true);
            }
        }
    };
    return (
        <div className="counterup-single-item text-center">
            {images && (
                <div className="icon mb-4">
                    <Image
                        className="align-middle"
                        src={images?.src}
                        alt={images?.alt || "Counter Icon"}
                    />
                </div>
            )}

            <div className="content">
                <CountUp start={focus ? 0 : null} end={number} duration={5}>
                    {({ countUpRef }) => (
                        <h3 className="counterup mb-3 text-red-500 text-[48px] font-semibold">
                            <span ref={countUpRef} />
                            <VisibilitySensor
                                onChange={(isVisible) =>
                                    visibleChangeHandler(isVisible)
                                }
                            >
                                <span className="plus">+</span>
                            </VisibilitySensor>
                        </h3>
                    )}
                </CountUp>
                <span className="text-white text-[25px] mt-4">{title}</span>
            </div>
        </div>
    );
};
FunFactArea.propTypes = {
    title: PropTypes.string,
    number: PropTypes.number.isRequired,
    images: PropTypes.shape(ImageType),
};

export default FunFactArea;
