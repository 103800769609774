/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import { ImageType } from "@streamo/helper/types";
import cn from "classnames";

const FooterTextWidget = ({ data }) => {
    const bgColor = {
        linkedin: "bg-linkedin",
        facebook: "bg-facebook",
        twitter: "bg-twitter",
        instagram: "bg-instagram",
    };
    const borderColor = {
        linkedin: " border-linkedin",
        facebook: "border-facebook",
        twitter: "border-twitter",
        instagram: "border-instagram",
    };
    return (
        <div className="footer-widget-wrap">
            <div className="widget-title mb-7">
                <Image
                    src={data?.logo[0].src}
                    alt={data?.logo[0].alt || data?.title}
                />
            </div>
            <p className="text-white text-sm">{data?.texts[0]?.content}</p>
            <div className="footer-single-info my-4 flex space-x-2">
                {data?.socials &&
                    data?.socials.map((item) => (
                        <a
                            key={item.id}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={item.title}
                            className={cn(
                                `inline-block text-white text-center h-9 w-9 leading-9
                                 rounded-full border-solid border hover:bg-transparent`,
                                bgColor[item.title],
                                borderColor[item.title]
                            )}
                        >
                            <i className={item.icon} />
                        </a>
                    ))}
            </div>
        </div>
    );
};

FooterTextWidget.propTypes = {
    data: PropTypes.shape({
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    }),
};

export default FooterTextWidget;
