import React from "react";
import PropTypes from "prop-types";

const defaultTag = "h2";

const Heading = ({ children, level, className, ...restProps }) => {
    const Tag = level?.trim() || defaultTag;
    return (
        <Tag className={className} {...restProps}>
            {children}
        </Tag>
    );
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    level: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]).isRequired,
    className: PropTypes.string,
};

export default Heading;
