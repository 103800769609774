import React from "react";
import PropTypes from "prop-types";
import { Heading, Image } from "gatsby-theme-ui";

const FeatureArea = ({ data }) => (
    <section className="feature-section section-space-ptb-90 bg-[#12222d]">
        <div className="container">
            <div className="grid gap-8 items-center grid-cols-1 sm:grid-cols-12">
                <div className="sm:col-span-7 order-2">
                    {data?.headings && (
                        <Heading
                            level={data?.headings[0]?.level}
                            className="title font-semibold text-white leading-[55px]"
                        >
                            {data?.headings[0]?.content}
                        </Heading>
                    )}
                    {data?.texts && (
                        <p className="text-white text-2xl mt-8">
                            {data?.texts[0]?.content}
                        </p>
                    )}
                </div>
                <div className="sm:col-span-5 text-center order-1">
                    {data?.images && (
                        <Image
                            className="image"
                            src={data?.images[0]?.src}
                            alt={data?.images[0]?.alt || "Feature Image"}
                        />
                    )}
                </div>
            </div>
        </div>
    </section>
);

FeatureArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            })
        ),
    }),
};

export default FeatureArea;
