import React from "react";
import PropTypes from "prop-types";

const FooterMenu = ({ data }) => (
    <div className="footer-widget-wrap">
        <ul className="footer-menu">
            {data?.menu &&
                data?.menu?.map((item) => (
                    <li
                        key={item.id}
                        className="text-white inline-block text-lg mr-8 last-of-type:mr-0"
                    >
                        <a href={item.link}>{item?.text}</a>
                    </li>
                ))}
        </ul>
    </div>
);

FooterMenu.propTypes = {
    data: PropTypes.shape({
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
        menu: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
    }),
};

export default FooterMenu;
