import React from "react";
import PropTypes from "prop-types";
import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";
import { HeroContent02 } from "gatsby-theme-component";
import { ItemType } from "@streamo/helper/types";

const HeroSlider = ({ data }) => (
    <SwiperSlider
        className="hero-four-streaming-slider relative group"
        options={{
            autoplay: false,
            slidesPerView: 1,
            navigation: true,
            pagination: false,
            spaceBetween: 0,
            loop: false,
            centeredSlides: false,
        }}
        navPosition={2}
    >
        {data?.items?.map((item) => (
            <SwiperSlide key={item.id}>
                <HeroContent02
                    title={item?.title}
                    image={item?.image}
                    coverImage={item?.coverImage}
                    slug={item?.slug}
                    quality={item?.quality}
                    rating={item?.rating}
                    watchTime={item?.watchTime}
                    comingSoon={item?.comingSoon}
                />
            </SwiperSlide>
        ))}
    </SwiperSlider>
);

HeroSlider.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default HeroSlider;
