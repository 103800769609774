import React, { useState } from "react";
import { useClickOutside } from "@streamo/helper/hooks";

const SearchBox = () => {
    const [toggle, setToggle] = useState(false);

    const triggerToggle = () => {
        setToggle((prev) => !prev);
    };
    const containerRef = useClickOutside(() => {
        setToggle(false);
    });
    const style = {
        active: `block`,
        iconHiddn: `!hidden`,
        actionButton: ` relative text-lg text-white text-center inline-block w-8 h-8 leading-8 rounded-full border border-solid border-white`,
        notifications: `absolute right-0 -top-[15px] bg-[#637282] text-white text-xs h-5 w-5 leading-5 rounded-full`,
        listStyle: `mb-2 last:mb-0`,
    };
    return (
        <div className="relative " ref={containerRef}>
            <button
                type="button"
                onClick={triggerToggle}
                className={style.actionButton}
            >
                <i
                    className={`zmdi zmdi-search  ${
                        toggle ? style.iconHiddn : " "
                    }`}
                />
                <i
                    className={`zmdi zmdi-close s-close ${
                        toggle ? " " : style.iconHiddn
                    }`}
                />
            </button>
            <div
                className={`${
                    toggle ? style.active : "hidden"
                } absolute z-20 sm:top-1/2 sm:-translate-y-1/2 -right-[90px] sm:right-10 w-72 md:w-80 top-[50px] `}
            >
                <input
                    className={`border border-solid relative text-sm 
                    border-gray-500 rounded-3xl w-full p-[9px_60px_9px_16px] focus:border focus:border-gray-500 focus:outline-none`}
                    type="text"
                    placeholder="Search"
                />
                <button
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 right-0 px-[9px_15px] text-lg bg-transparent p-[0_16px] leading-10 border-l border-solid border-gray-500"
                >
                    <i className="zmdi zmdi-search" />
                </button>
            </div>
        </div>
    );
};

export default SearchBox;
