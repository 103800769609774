import React, { useState } from "react";
import { useClickOutside } from "@streamo/helper/hooks";
import { StaticImage } from "gatsby-plugin-image";

const ProfileCard = () => {
    const [toggle, setToggle] = useState(false);

    const triggerToggle = () => {
        setToggle((prev) => !prev);
    };
    const containerRef = useClickOutside(() => {
        setToggle(false);
    });
    const style = {
        active: `block`,
        notifications: `absolute right-0 -top-[15px] bg-[#637282] text-white text-xs h-5 w-5 leading-5 rounded-full`,
        listStyle: `mb-2 last:mb-0 text-sm`,
    };
    return (
        <div className="user-profile relative " ref={containerRef}>
            <div className="profile-photo cursor-pointer">
                <StaticImage
                    onClick={triggerToggle}
                    src="../../src/images/user/author-01.png"
                    alt="A author"
                    placeholder="blurred"
                />
            </div>
            <ul
                className={`dropdown-menu ${
                    toggle ? style.active : "hidden"
                } w-40 absolute top-[60px] right-0 bg-white z-10 py-5 px-4`}
            >
                <li className={style.listStyle}>
                    <a href="/my-account">My Profile</a>
                </li>
                <li className={style.listStyle}>
                    <a href="/my-account">My Account</a>
                </li>
                <li className={style.listStyle}>
                    <a href="/login-register/">Log Out</a>
                </li>
            </ul>
        </div>
    );
};

export default ProfileCard;
