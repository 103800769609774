/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { BreadCrumb1 } from "gatsby-theme-component";

const PageHeader = ({ data }) => (
    <div className="page-title-area bg-primary-90 text-white">
        {data?.pageName && <BreadCrumb1 pageName={data.pageName} showTitle />}
    </div>
);
PageHeader.propTypes = {
    data: PropTypes.shape({
        pageName: PropTypes.string,
    }),
};

export default PageHeader;
