import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import cn from "classnames";

const sizeStyle = {
    small: `px-6 py-2`,
    medium: `px-5 py-3 lg:px-8 lg:py-5`,
    large: `px-8 py-8`,
};
const shapeStyle = {
    rounded: `rounded-full`,
    square: `rounded`,
};
const colorStyle = {
    primary: `bg-red-500 text-white hover:bg-red-600 transition ease-in-out`,
    secondary: `bg-white text-black shadow-lg shadow-[0 0 25px rgb(0 0 0 / 30%)] hover:bg-black hover:text-white transition-all`,
    orange: `bg-orange text-white hover:bg-black hover:text-white transition-all`,
    danger: `bg-red-500 text-white hover:bg-red-700 hover:text-white transition-all`,
    dark: `bg-[#333] text-white hover:bg-red-500 transition-all`,
};

const Button = ({ children, type, path, className, size, shape, color }) => {
    const btnstyle = {
        size: size || "medium",
        shape: shape || "square",
        color: color || "primary",
    };
    const buttonClasses = cn(
        className,
        "inline-block",
        "text-center",
        sizeStyle[btnstyle.size],
        shapeStyle[btnstyle.shape],
        colorStyle[btnstyle.color]
    );
    if (path) {
        const internal = /^\/(?!\/)/.test(path);
        const isHash = path.startsWith("#");

        if (internal) {
            return (
                <Link to={path} className={buttonClasses}>
                    {children}
                </Link>
            );
        }
        if (isHash) {
            return (
                <button className={buttonClasses} type="button">
                    {children}
                </button>
            );
        }
        return (
            <a
                href={path}
                target="_blank"
                className={buttonClasses}
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button style={size} type={type} className={buttonClasses}>
            {children}
        </button>
    );
};
Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    path: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    shape: PropTypes.oneOf(["square", "rounded", "oval"]),
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "dark",
        "light",
        "orange",
        "danger",
    ]),
};
Button.defaultProps = {
    type: "button",
};
export default Button;
