import React from "react";
import PropTypes from "prop-types";
import { SectionTitleThree } from "gatsby-theme-component";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from "react-accessible-accordion";
import { SectionTitleType } from "@streamo/helper/types";

const FAQSection = ({ data }) => (
    <section className="faq-section relative section-space-ptb-90 overflow-hidden bg-[#12222d]">
        <div className="container">
            {data?.section_title && (
                <SectionTitleThree className="mb-3" {...data?.section_title} />
            )}
            <div className="max-w-4xl mx-auto">
                {data?.items && (
                    <Accordion>
                        {data?.items?.map((item) => (
                            <AccordionItem
                                id={item?.id}
                                key={item?.id}
                                className="mb-5"
                            >
                                <AccordionItemHeading>
                                    <AccordionItemButton className="accordion-btn relative font-medium text-base pl-[15px] pr-4 md:pr-[60px] py-6 bg-black text-white">
                                        {item?.title}
                                        <span className="number-of-accordion" />
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div className="card-body leading-7 p-5 shadow-md text-white bg-black shadow-white/1">
                                        {item?.description}
                                    </div>
                                </AccordionItemPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                )}
            </div>
        </div>
    </section>
);
FAQSection.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        section_title: PropTypes.shape(SectionTitleType),
    }),
};
export default FAQSection;
