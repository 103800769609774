import React from "react";
import PropTypes from "prop-types";
import { ImageType } from "@streamo/helper/types";
import { OffCanvas, OffCanvasHeader, OffCanvasBody } from "gatsby-theme-ui";
import { MobileMenu } from "../menu";

const MenuOffcanvas = ({ isOpen, onClick, menu, ...rest }) => (
    <OffCanvas isOpen={isOpen} onClick={onClick} {...rest} className="py-11">
        <OffCanvasHeader onClick={onClick} />
        <OffCanvasBody>{menu && <MobileMenu menu={menu} />}</OffCanvasBody>
    </OffCanvas>
);
MenuOffcanvas.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
};
export default MenuOffcanvas;
