import { Link } from "gatsby";
import PropTypes from "prop-types";
import { ImageType, ButtonType } from "@streamo/helper/types";
import { Logo } from "gatsby-theme-component";

const Header = ({ data }) => (
    <header className="header absolute top-0 z-10 w-full">
        <div className="container-full px-4 md:px-24 bg-transparent mx-auto z-[99999] top-0 w-full">
            <div className="flex justify-between items-center">
                <div className="left-sidebar py-8 flex items-center">
                    {data?.logo && <Logo mainLogo={data.logo?.[0]} />}
                </div>
                <div className="right-sidebar flex items-center space-x-4">
                    <Link
                        to="/streaming-two/login-register"
                        className="text-white text-lg
                            font-semibold bg-red-500 px-5 py-2 rounded-md
                            hover:bg-red-600 "
                    >
                        {" "}
                        Sign In
                    </Link>
                </div>
            </div>
        </div>
    </header>
);
Header.propTypes = {
    data: PropTypes.shape({
        logo: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        button: PropTypes.shape(ButtonType),
        menu: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};
export default Header;
