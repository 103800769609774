/* eslint-disable max-len */
import React from "react";

const TabContentItem = () => (
    <div className="tab-content-box max-w-xl mx-auto">
        <form className="login-inner-form grid grid-cols-1 gap-5" action="">
            <div className="input-group">
                <input
                    className="bg-primary-90 w-full py-4 px-4 placeholder:text-white text-white focus:outline-none focus:shadow-outline"
                    type="text"
                    id="con_name"
                    placeholder="User Name"
                    required="name is required"
                />
            </div>
            <div className="input-group">
                <input
                    className="bg-primary-90 text-[15px] w-full py-3 px-4 placeholder:text-white text-white focus:outline-none focus:shadow-outline"
                    type="password"
                    placeholder="Password"
                    id="current_password"
                    required="password is required"
                />
            </div>
            <div className="button-box">
                <div className="login-toggle-btn">
                    <input id="checkbox" type="checkbox" />
                    <label htmlFor="checkbox" className="text-white ml-2">
                        Remember me
                    </label>
                    <a href="/" className="text-red-500 float-right">
                        Forgot Password?
                    </a>
                </div>
                <div className="button-box mt-5">
                    <button
                        className="login-btn text-white bg-red-500 px-6 py-2 rounded-sm hover:bg-red-600"
                        type="submit"
                    >
                        <span>Login</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
);
export default TabContentItem;
