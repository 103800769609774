import React from "react";
import PropTypes from "prop-types";
import { ItemType } from "@streamo/helper/types";
import { PricingCard01 } from "gatsby-theme-component";

const PricingPlanArea = ({ data }) => (
    <section className="pricing-plan-area section-space-pt-90 section-space-pb-50 bg-black">
        <div className="container">
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {data?.items &&
                    data?.items.map((item) => (
                        <PricingCard01
                            key={item.id}
                            price={item?.title}
                            title={item?.designation}
                            features={item?.features}
                            buttons={item?.buttons}
                            texts={item?.texts}
                            activeClass={item?.activeClass}
                        />
                    ))}
            </div>
        </div>
    </section>
);

PricingPlanArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default PricingPlanArea;
