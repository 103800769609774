import React from "react";
import PropTypes from "prop-types";
import { Feature01 } from "gatsby-theme-component";
import { ItemType, SectionTitleType } from "@streamo/helper/types";

const FeatureArea = ({ data }) => (
    <section className="feature-section section-space-ptb-90 bg-primary-90">
        <div className="container">
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                {data?.items?.map((item) => (
                    <Feature01
                        key={item.id}
                        images={item?.images[0]}
                        title={item?.title}
                        description={item?.description}
                    />
                ))}
            </div>
        </div>
    </section>
);

FeatureArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default FeatureArea;
