import PropTypes from "prop-types";
import { memo } from "react";
import cn from "classnames";

const OffCanvasBody = ({ children, className }) => (
    <div className={cn("offcanvas-body", className)}>{children}</div>
);

OffCanvasBody.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default memo(OffCanvasBody);
