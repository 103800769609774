import React from "react";
import PropTypes from "prop-types";
import { Image, SwiperSlider, SwiperSlide } from "gatsby-theme-ui";

const FooterPartner = ({ data }) => (
    <div className="footer-widget-wrap m-[25px_0]">
        <SwiperSlider
            className="streaming-brand-slider"
            options={{
                autoplay: false,
                slidesPerView: 2,
                navigation: false,
                pagination: false,
                spaceBetween: 30,
                loop: true,
                centeredSlides: false,
                breakpoints: {
                    672: {
                        slidesPerView: 3,
                    },
                    1024: {
                        slidesPerView: 4,
                    },
                    1200: {
                        slidesPerView: 6,
                    },
                },
            }}
            navPosition={2}
        >
            {data?.partner &&
                data?.partner?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <a
                            href="/"
                            className="text-center border border-solid border-[#444] block"
                        >
                            <Image
                                src={item?.images[0]?.src}
                                alt={item?.images[0]?.alt}
                            />
                        </a>
                    </SwiperSlide>
                ))}
        </SwiperSlider>
    </div>
);

FooterPartner.propTypes = {
    data: PropTypes.shape({
        socials: PropTypes.arrayOf(PropTypes.shape({})),
        title: PropTypes.string,
        partner: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default FooterPartner;
