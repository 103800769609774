import React from "react";
import PropTypes from "prop-types";
import {
    ImageType,
    ItemType,
    SectionTitleType,
    TextType,
} from "@streamo/helper/types";
import { SectionTitle } from "gatsby-theme-component";
import { Image } from "gatsby-theme-ui";

const SupportSolution = ({ data }) => (
    <section className="support-solution-section bg-black section-space-ptb-90">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-8 items-center text-white">
                <div className="md:col-span-7">
                    {data?.section_title && (
                        <SectionTitle
                            className="mb-6"
                            {...data?.section_title}
                        />
                    )}
                    {data?.texts &&
                        data?.texts.map((text) => (
                            <p className="mb-6" key={text.id}>
                                {text?.content}
                            </p>
                        ))}
                    {data?.items && (
                        <div className="list-disc mt-9">
                            {data?.items.map((item) => (
                                <div
                                    key={item.id}
                                    className="single-list-dec pl-8 relative last:mb-0 mb-6"
                                >
                                    <h5 className="mb-4 font-normal">
                                        <i className="zmdi zmdi-check absolute left-0 " />
                                        {item?.title}
                                    </h5>
                                    <p className="">{item?.description}</p>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className="md:col-span-5 order-1 md:order-2 text-center">
                    <Image
                        src={data?.images[0]?.src}
                        alt={data?.images[0]?.alt}
                    />
                </div>
            </div>
        </div>
    </section>
);

SupportSolution.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    }),
};

export default SupportSolution;
