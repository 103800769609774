import { Fragment } from "react";
import PropTypes from "prop-types";
import { getClosest, getSiblings } from "@streamo/helper/methods";

const MobileMenu = ({ menu }) => {
    const removeClassFromChildren = (parent) => {
        for (let i = 0; i < parent.children.length; i++) {
            if (parent.children[i].tagName === "UL") {
                const child = parent.children[i].children;
                for (let j = 0; j < child.length; j++) {
                    child[j].classList.remove("submenu-open");
                }
            }
        }
    };

    const onClickHandler = (e, selector) => {
        const { target } = e;
        const parentEl = target.parentElement;
        if (
            parentEl.classList.contains("menu-expand") ||
            target.classList.contains("menu-expand")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, selector);
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                sibling.classList.remove("submenu-open");
                removeClassFromChildren(sibling);
            });
            removeClassFromChildren(parent);
            parent.classList.toggle("submenu-open");
        }
    };

    return (
        <ul className="mobile-menu pt-[35px] px-[30px] pb-[80px]">
            {menu.map((item, i) => {
                const { submenu } = item;
                const hasSubmenu = submenu?.length > 0;

                return (
                    <li
                        key={item.id}
                        className="menu-item relative"
                        id={`menu-item-${i}`}
                    >
                        <a
                            href={item.link}
                            className="block text-black border-b border-solid border-black/20 pb-3 mb-3"
                        >
                            {item.text}
                        </a>

                        {!!hasSubmenu && (
                            <Fragment>
                                <button
                                    type="button"
                                    className="menu-expand text-lg mb-1 text-black absolute right-0 -top-1 w-10 h-10 bg-transparent text-center leading-10 cursor-pointer"
                                    onClick={(e) =>
                                        onClickHandler(e, `#menu-item-${i}`)
                                    }
                                >
                                    <i className=" zmdi zmdi-chevron-down" />
                                </button>
                                <ul className="submobilemenu-nav pl-4">
                                    {submenu.map((subitem) => (
                                        <li
                                            key={subitem.id}
                                            className="menu-item border-b border-solid border-black/20 pb-3 mb-3"
                                            id={`submenu-item-${i}`}
                                        >
                                            <a
                                                href={subitem.link}
                                                className="text-black"
                                            >
                                                {subitem.text}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </Fragment>
                        )}
                    </li>
                );
            })}
        </ul>
    );
};

MobileMenu.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
export default MobileMenu;
