/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import { ImageType, SocialType } from "@streamo/helper/types";

const Team = ({ title, images, socials, designation }) => (
    <div className="single-team text-center group">
        <div className="team-image relative transition-all group-hover:bg-[#ffda84]">
            {images && (
                <Image
                    className="mt-8"
                    src={images?.src}
                    alt={images?.alt || title}
                />
            )}
            {socials && (
                <ul className="team-socail w-full absolute -bottom-5 left-1/2 -translate-x-1/2">
                    {socials?.map((social) => (
                        <li key={social?.id} className="inline-block mx-1">
                            <a
                                href={social?.link}
                                className="bg-[#ffc61b] opacity-0 invisible group-hover:visible group-hover:opacity-100 group-hover:animate-rollIn text-white text-sm block h-10 w-10 leading-10 text-center rounded-full"
                            >
                                <i className={social?.icon} />
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
        <div className="team-info mt-6">
            {title && <h5 className="font-semibold">{title}</h5>}
            {designation && <p>{designation}</p>}
        </div>
    </div>
);

Team.propTypes = {
    title: PropTypes.string,
    socials: PropTypes.arrayOf(PropTypes.shape(SocialType)),
    images: PropTypes.shape(ImageType),
    designation: PropTypes.string,
};

export default Team;
