import React from "react";
import PropTypes from "prop-types";
import { Heading, Image } from "gatsby-theme-ui";
import {
    ButtonType,
    HeadingType,
    ImageType,
    ItemType,
    TextType,
} from "@streamo/helper/types";

const WhyChooseUsArea = ({ data }) => (
    <section className="why-choose-us-section bg-black text-white section-space-ptb-90">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div className="about-cont">
                    <Heading
                        level={data?.headings[0]?.level}
                        className="mb-4 md:mb-6 font-normal"
                    >
                        {data?.headings[0]?.content}
                    </Heading>

                    {data?.texts &&
                        data?.texts.map((item) => (
                            <p key={item.id} className="mb-5 leading-7">
                                {item.content}
                            </p>
                        ))}
                </div>
                <div className="order-first md:order-last">
                    <Image
                        src={data?.images[0]?.src}
                        alt={data?.images[0]?.alt || "Streamo Image"}
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-10">
                {data?.items.map((item) => (
                    <div className="text-content mt-10" key={item.id}>
                        {item?.title && (
                            <h5 className="mb-4 font-semibold">
                                {item?.title}
                            </h5>
                        )}
                        {item.texts &&
                            item.texts.map((text) => (
                                <p
                                    key={text.id}
                                    className="mb-5 text-sm leading-7"
                                >
                                    {text.content}
                                </p>
                            ))}
                        <ul className="list-none">
                            {item.features &&
                                item.features.map((text) => (
                                    <li
                                        key={text.id}
                                        className="mb-4 text-sm last-of-type:mb-0"
                                    >
                                        <i className="mr-5 zmdi zmdi-check" />
                                        {text.content}
                                    </li>
                                ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    </section>
);

WhyChooseUsArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default WhyChooseUsArea;
