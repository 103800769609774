/* eslint-disable max-len */
import { Children } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";
import { SectionTitleType } from "@streamo/helper/types";

const MyAccountTabArea = ({ children }) => {
    const childArr = Children.toArray(children);
    return (
        <section className="my-account-section bg-black section-space-ptb-90 section-space-pb-50">
            <div className="container">
                <Tabs>
                    <TabList className="">
                        {childArr?.map((item) => (
                            <Tab
                                key={item.key}
                                className="my-account-nav mb-14 text-white pb-2 inline-block mr-7"
                            >
                                {item?.props?.data?.section_title?.title && (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: item.props.data
                                                .section_title.title,
                                        }}
                                    />
                                )}
                            </Tab>
                        ))}
                    </TabList>
                    <div className="tab-content">
                        {childArr?.map((item) => (
                            <TabPanel key={item.key}>{item}</TabPanel>
                        ))}
                    </div>
                </Tabs>
            </div>
        </section>
    );
};
MyAccountTabArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
    }),
    children: PropTypes.node.isRequired,
};
export default MyAccountTabArea;
