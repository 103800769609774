import PropTypes from "prop-types";
import { BannerContent02 } from "gatsby-theme-component";
import { ItemType } from "@streamo/helper/types";

const BannerSection = ({ data }) => (
    <section className="bg-black section-space-pt-50">
        {data?.items?.slice(0, 1).map((item) => (
            <BannerContent02
                key={item.id}
                coverImage={item?.coverImage}
                slug={item?.slug}
            />
        ))}
    </section>
);

BannerSection.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default BannerSection;
