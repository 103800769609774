import React, { useState } from "react";
import { useClickOutside } from "@streamo/helper/hooks";
import { StaticImage } from "gatsby-plugin-image";

const NotificationCard = () => {
    const [toggle, setToggle] = useState(false);

    const triggerToggle = () => {
        setToggle((prev) => !prev);
    };

    const containerRef = useClickOutside(() => {
        setToggle(false);
    });
    const style = {
        active: `block`,
        actionButton: `notifications-iocn relative text-white text-center inline-block w-8 h-8 leading-8 rounded-full border border-solid border-white`,
        notifications: `absolute right-0 -top-[15px] bg-[#637282] text-white text-xs h-5 w-5 leading-5 rounded-full`,
        listStyle: `mb-2 last:mb-0`,
    };
    return (
        <div className="notification-wrap relative" ref={containerRef}>
            <div className="notification-card ">
                <button
                    onClick={triggerToggle}
                    type="button"
                    className={style.actionButton}
                >
                    <i className="zmdi zmdi-notifications" />
                    <span className={style.notifications}>2</span>
                </button>
            </div>
            <ul
                className={`dropdown-menu ${
                    toggle ? style.active : "hidden"
                } w-60 absolute top-[60px] right-0 bg-white z-10 py-5 px-5`}
            >
                <h6 className="mb-4 pb-4 border-b border-solid border-[#ddd]">
                    Notifications
                </h6>
                <li className="single-notifications mb-4">
                    <a href="/" className="flex text-sm">
                        <span className="image w-10 shrink-0">
                            <StaticImage
                                src="../../src/images/user/author-01.png"
                                alt="A author"
                                placeholder="blurred"
                            />
                        </span>
                        <span className="notific-contents ml-2">
                            <span>Lorem ipsum dolor sit amet consectetur.</span>
                            <span className="time block">16 hours ago</span>
                        </span>
                    </a>
                </li>
                <li className="single-notifications">
                    <a href="/" className="flex text-sm">
                        <span className="image w-10 shrink-0">
                            <StaticImage
                                src="../../src/images/user/author-01.png"
                                alt="A author"
                                placeholder="blurred"
                            />
                        </span>
                        <span className="notific-contents ml-2">
                            <span>Lorem ipsum dolor sit amet consectetur.</span>
                            <span className="time block">21 hours ago</span>
                        </span>
                    </a>
                </li>
            </ul>
        </div>
    );
};
export default NotificationCard;
