import { memo } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import CloseButton from "../../close-button";

const OffCanvasHeader = ({ onClick, className }) => (
    <div
        className={cn(
            "offcanvas-header flex justify-between px-7 items-center",
            className
        )}
    >
        <div className="w-full text-right">
            <CloseButton
                onClick={onClick}
                label="click here to close offcanvas"
            />
        </div>
    </div>
);

OffCanvasHeader.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default memo(OffCanvasHeader);
