import React from "react";
import PropTypes from "prop-types";
import { Heading, Image } from "gatsby-theme-ui";

const LandingHeroArea = ({ data }) => (
    <section className="landing-hero-section relative h-screen bg-slate-100 flex items-center">
        <span className="after:absolute after:content-[''] z-[3] after:left-0 after:top-0 after:w-full after:h-screen after:bg-black/60" />
        <div className="container relative z-10">
            <div className="landing-hero-content text-white mt-12 py-20 text-center mx-auto max-w-3xl">
                <Heading
                    level={data?.headings[0]?.level}
                    className="title lg:text-[60px]"
                >
                    {data?.headings[0]?.content}
                </Heading>
                <Heading
                    level={data?.headings[1]?.level}
                    className="sub-title my-5"
                >
                    {data?.headings[1]?.content}
                </Heading>
                <p>{data?.texts[0]?.content}</p>
                <div className="create-membership-wrap mt-8 mx-auto relative">
                    <input
                        placeholder="Email Address"
                        className="w-full bg-white pl-4 pr-[200px] py-5 relative rounded-[4px] text-black placeholder:text-black focus:outline-none focus:shadow-outline"
                    />
                    <button
                        className="landing-btn-style h-16 leading-[64px] px-8 text-center bg-red-500 hover:bg-red-600 transition-all text-white absolute rounded-[0_4px_4px_0] right-0"
                        type="button"
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </div>
        <div className="hero-image absolute left-0 top-0 h-screen w-full">
            <Image
                className="bg-cover bg-no-repeat w-full h-full object-cover"
                src={data?.images[0]?.src}
                alt={data?.images[0]?.alt || "Hero Image"}
            />
        </div>
    </section>
);

LandingHeroArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(
            PropTypes.shape({
                level: PropTypes.string,
                content: PropTypes.string,
            })
        ),
        texts: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        buttons: PropTypes.arrayOf(
            PropTypes.shape({
                content: PropTypes.string,
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]).isRequired,
                alt: PropTypes.string,
            })
        ),
    }),
};

export default LandingHeroArea;
