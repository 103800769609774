/* eslint-disable import/no-relative-packages */
import { useState } from "react";
import PropTypes from "prop-types";
import {
    ImageType,
    TextType,
    SectionTitleType,
    VideoButtonType,
} from "@streamo/helper/types";
import { SectionTitle } from "gatsby-theme-component";
// eslint-disable-next-line import/no-relative-packages
import { Image, ModalVideo } from "gatsby-theme-ui";

const HowItWork = ({ data }) => {
    const [isOpen, setIsOpen] = useState(false);
    const onClickHandler = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <section className="how-it-work-section section-space-ptb-90 relative bg-[#12222d]">
            <div className="container z-10 relative">
                {data?.section_title && (
                    <SectionTitle
                        className="max-w-[600px] mx-auto mb-16 lg:mb-14"
                        {...data?.section_title}
                    />
                )}
                <div className="video-inner-wrap text-center relative">
                    <Image src={data?.images[0].src} alt="how it works BG" />
                    <button
                        onClick={onClickHandler}
                        type="button"
                        aria-label="button"
                        className="text-3xl text-white bg-red-500 hover:bg-white hover:text-red-500 absolute top-[47%] left-[47%] h-20 w-20 leading-[80px] -translate-x-1/2 -translate-y-1/2 rounded-full"
                    >
                        <i className="zmdi zmdi-play" />
                    </button>
                </div>
                <ModalVideo
                    isOpen={isOpen}
                    channel={data?.video_button?.video_platform}
                    videoId={data?.video_button?.video_id}
                    onClose={onClickHandler}
                />
            </div>
        </section>
    );
};
HowItWork.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        video_button: PropTypes.shape(VideoButtonType),
    }),
};

export default HowItWork;
