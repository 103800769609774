import React from "react";
import PropTypes from "prop-types";
import { ItemType, SectionTitleType } from "@streamo/helper/types";
import { SectionTitle, Team } from "gatsby-theme-component";

const TeamArea = ({ data }) => (
    <section className="team-section section-space-ptb-90 bg-[#ECF3FE]">
        <div className="container">
            {data?.section_title && (
                <SectionTitle
                    className="max-w-[600px] mx-auto mb-16 lg:mb-14"
                    {...data?.section_title}
                />
            )}
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                {data?.items?.map((item) => (
                    <Team
                        key={item.id}
                        title={item?.title}
                        images={item.images[0]}
                        socials={item?.socials}
                        designation={item?.designation}
                    />
                ))}
            </div>
        </div>
    </section>
);

TeamArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
        section_title: PropTypes.shape(SectionTitleType),
    }),
};

export default TeamArea;
