import React from "react";

const NotFound = () => (
    <section className="section-space-ptb">
        <div className="container">
            <div className="not-found text-center">
                <h1 className="mb-10">404</h1>
                <h2 className="mb-10">Ohhh! Page Not Found</h2>
                <p>
                    It seems we can not find what you are looking for. Perhaps
                    searching can help or go back to{" "}
                    <a className="text-red-500" href="/">
                        Homepage
                    </a>
                    .
                </p>
            </div>
        </div>
    </section>
);

export default NotFound;
