import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { ImageType } from "@streamo/helper/types";
import { Image } from "gatsby-theme-ui";

const BannerContent = ({ title, slug, quality, watchTime, coverImage }) => (
    <div className="single-slider relative h-auto md:h-[600px] bg-slate-100 bg-cover bg-bottom bg-no-repeat flex items-center">
        <div className="container-full px-20 py-16 md:py-0">
            <div className="product-contents transition-[all_0.3s_ease_0s] relative z-10">
                {title && (
                    <h2 className="font-bold text-3xl md:text-5xl lg:text-7xl mb-1 transition-[all_0.5s_ease_0s]">
                        <Link to={slug} className="text-white">
                            {title}
                        </Link>
                    </h2>
                )}
                <div className="flex text-xl mt-4">
                    {quality && (
                        <span className="text-white mb-2 block transition-[all_0.7s_ease_0s]">
                            Quality : {quality}
                        </span>
                    )}
                    <span className="px-3 text-white">|</span>
                    {quality && (
                        <span className="text-white mb-2 block transition-[all_0.7s_ease_0s]">
                            {watchTime}
                        </span>
                    )}
                </div>
                <div className="button mt-5 transition-[all_0.8s_ease_0s]">
                    <Link
                        to={slug}
                        className="text-white bg-red-500 inline-block text-center px-5 py-3 rounded-sm"
                    >
                        Watch Now
                    </Link>
                </div>
            </div>
        </div>
        {coverImage?.src && (
            <div className="hero-image absolute left-0 top-0 h-full w-full">
                <Image
                    className="bg-cover object-cover bg-no-repeat h-full w-full"
                    src={coverImage?.src}
                    alt={coverImage?.alt || title || "Hero Image"}
                />
            </div>
        )}
    </div>
);

BannerContent.propTypes = {
    title: PropTypes.string,
    slug: PropTypes.string,
    watchTime: PropTypes.string,
    quality: PropTypes.string,
    coverImage: PropTypes.shape(ImageType),
};

export default BannerContent;
