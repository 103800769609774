import React from "react";
import PropTypes from "prop-types";
import { SectionTitleType, ButtonType, ItemType } from "@streamo/helper/types";
import { SwiperSlider, SwiperSlide } from "gatsby-theme-ui";
import { SectionTitleThree, StreamItemCardTwo } from "gatsby-theme-component";

const VideoStreamingContainer = ({ data }) => (
    <section className="seciton bg-black section-space-ptb-60 ">
        <div className="container">
            {data?.section_title && (
                <SectionTitleThree className="mb-3" {...data?.section_title} />
            )}
            <SwiperSlider
                className="streaming-two-slider"
                options={{
                    autoplay: true,
                    slidesPerView: 1,
                    navigation: false,
                    pagination: false,
                    spaceBetween: 30,
                    loop: false,
                    centeredSlides: false,
                    breakpoints: {
                        420: {
                            slidesPerView: 2,
                        },
                        672: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                    },
                }}
                navPosition={2}
            >
                {data?.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <StreamItemCardTwo
                            title={item?.title}
                            image={item?.image}
                            slug={item?.slug}
                            quality={item?.quality}
                            rating={item?.rating}
                            comingSoon={item?.comingSoon}
                        />
                    </SwiperSlide>
                ))}
            </SwiperSlider>
        </div>
    </section>
);

VideoStreamingContainer.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default VideoStreamingContainer;
