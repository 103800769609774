import React from "react";
import { ContactFormDark } from "gatsby-theme-component";

const ContactUs = () => (
    <section className="contact-us-form-section bg-black section-space-ptb-90">
        <div className="container">
            <div className="grid">
                <ContactFormDark />
            </div>
        </div>
    </section>
);

ContactUs.propTypes = {};

export default ContactUs;
