/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import { HeadingType, ItemType, TextType } from "@streamo/helper/types";

const TabContentItem = () => (
    <div className="tab-content-box">
        <div className="card-info">
            <div className="top-area mb-8">
                <h3 className="title mb-5 text-white">Current Plan</h3>
            </div>
            <div className="current-plan-area grid grid-cols-12 gap-8 bg-primary-90">
                <div className="col-span-3">
                    <div className="dic-pricing-header px-8 py-10 text-center text-white">
                        <h5 className="package uppercase">Gold</h5>
                        <div className="price">
                            <span className="currency text-[11px] top-3 align-top">
                                $
                            </span>
                            <span className="amount text-6xl">39</span>
                            <span className="period text-[11px] uppercase">
                                /mo
                            </span>
                        </div>
                        <div className="old-price">
                            <span className=" text-lg opacity-70 line-through">
                                {" "}
                                $ 45/mo
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-span-6">
                    <div className="dic-pricing-items  px-8 py-10">
                        <ul className="text-white">
                            <li className="w-1/2 px-8 float-right mb-4">
                                8GB RAM
                            </li>
                            <li className="w-1/2 px-8 float-right mb-4">
                                50GB Storage
                            </li>
                            <li className="w-1/2 px-8 float-right mb-4">
                                1TB Bandwidth
                            </li>
                            <li className="w-1/2 px-8 float-right mb-4">
                                500 Databases
                            </li>
                            <li className="w-1/2 px-8 float-right">
                                Unlimited Accounts
                            </li>
                            <li className="w-1/2 px-8 float-right ">
                                Free Support
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="col-span-3">
                    <div className="dic-pricing-btn  px-8 py-10">
                        <button
                            type="submit"
                            className=" bg-red-500 text-[14px] rounded-md text-white hover:bg-red-600 px-4 py-3"
                        >
                            Upgrade Plan
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-info-change mt-10">
            <div className="top-area mb-8">
                <h3 className="title mb-5 text-white">Payment Method</h3>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-12">
                <div className="image lg:mr-8">
                    <StaticImage
                        src="../../credit-card.png"
                        alt="A author"
                        className="rounded-md"
                    />
                </div>
                <div className="content">
                    <h6 className="text-white mb-4">Refer a Friend and SAVE</h6>
                    <p className="text-white mb-4">
                        Receive 1 MONTH FREE for each of your friends that
                        purchase an account.
                    </p>
                    <button
                        type="submit"
                        className="bg-red-500 text-[14px] rounded-md text-white hover:bg-red-600 px-4 py-3"
                    >
                        Refer a Friend
                    </button>
                </div>
            </div>
            <div className="button mt-8">
                <button
                    type="submit"
                    className="bg-red-500 text-[14px] rounded-md text-white hover:bg-red-600 px-4 py-3"
                >
                    Edit this card
                </button>
                <br />
                <button
                    type="submit"
                    className="bg-red-500 mt-3 text-[14px] rounded-md text-white hover:bg-red-600 px-4 py-3"
                >
                    Add new payment method
                </button>
            </div>
        </div>
    </div>
);
TabContentItem.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};
export default TabContentItem;
