import React from "react";
import PropTypes from "prop-types";
import { Button } from "gatsby-theme-ui";
import { ButtonType } from "@streamo/helper/types";

const PricingCard = ({ price, title, features, buttons, activeClass }) => (
    <div className="pricing-card relative">
        <div className="pricing-panel bg-[#222] relative overflow-hidden text-center shadow-md rounded">
            <div
                className={`${
                    activeClass ? "before:bg-red-500" : "before:bg-[#444]"
                } pricing-heading relative px-5 pt-[70px] pb-8 before:rounded-[100%]
                before:absolute before:w-[610px] before:h-[300px] before:-top-44 before:content-[' ']
                before:-left-16 transform before:-rotate-[22deg] transition-all`}
            >
                {price && (
                    <h2 className="text-white font-medium relative">{price}</h2>
                )}
                {title && (
                    <h5 className="text-white font-medium mt-2 relative">
                        {title}
                    </h5>
                )}
            </div>
            {features && (
                <ul className="pricing-body mt-5">
                    {features.map((feature) => (
                        <li
                            key={feature.id}
                            className="mb-5 text-white last:mb-0 block"
                        >
                            {feature.content}
                        </li>
                    ))}
                </ul>
            )}
            {buttons && (
                <div className="pricing-footer pt-8 pb-10">
                    {buttons?.map((button) => (
                        <Button
                            key={button?.id}
                            path={button?.path}
                            variant={button?.variant}
                            size={button?.size}
                            color={button?.color}
                        >
                            {button.content}
                        </Button>
                    ))}
                </div>
            )}
        </div>
    </div>
);

PricingCard.propTypes = {
    price: PropTypes.string,
    title: PropTypes.string,
    activeClass: PropTypes.string,
    features: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            content: PropTypes.string,
        })
    ),
    buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
};

export default PricingCard;
